import { Link, navigate } from 'gatsby';
import React, { FunctionComponent, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';
import { FabricaFormulari } from './Formulari';
import { usaUsuari } from './Usuari';
import { useLocation } from '@reach/router';
import { Alert } from 'react-bootstrap';

type ParàmetresRecuperarContrasenya = { correu_electrònic: string };

const { Formulari, Camp, BotóDEnviar } = FabricaFormulari<ParàmetresRecuperarContrasenya>();

export const RecuperarLaContrasenya: FunctionComponent<{}> = () => {
  const { recuperaLaContrasenya } = usaUsuari();
  const { state } = useLocation() as { state?: { correuElectrònic?: string } };
  const [{ èxit, correuElectrònic }, posaEnviat] = useState({ èxit: true, correuElectrònic: '' });

  async function enEnviar({ correu_electrònic: correuElectrònic }: ParàmetresRecuperarContrasenya) {
    const èxit = await recuperaLaContrasenya(correuElectrònic);
    posaEnviat({ èxit, correuElectrònic });
    return {};
  }

  return (
    <Formulari envia={enEnviar} valorsInicials={{ correu_electrònic: state?.correuElectrònic }}>
      {correuElectrònic && (
        èxit 
          ? <Alert variant="success">Hem enviat un enllaç per recuperar la contrasenya a <i>{correuElectrònic}</i>.</Alert>
          : <Alert variant="warning">No hi ha ningú registrat amb el correu electrònic <i>{correuElectrònic}</i></Alert>
      )}
      <Stack gap={3}>
        <Form.Group>
          <Form.Label>Correu electrònic</Form.Label>
          <Camp nom="correu_electrònic" tipus="email" ocupant="Correu electrònic"/>
        </Form.Group>
        <BotóDEnviar>
          Recuperar contrasenya
        </BotóDEnviar>
      </Stack>
    </Formulari>
  );
};
